import { createLogger } from "vuex";
import { createStore } from "vuex";

import auth from "./modules/auth";
import vehicleInspection from "./modules/vehicle-inspection";
import general from "./modules/general";

const debug = import.meta.env.DEV;

const store = createStore({
  modules: {
    auth,
    vehicleInspection,
    general,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  actions: {
    initialiseStore(context) {
      return Promise.all([
        context.dispatch("vehicleInspection/initialiseStore")
      ])
    },
  },
});

store.subscribe((mutation) => {
  if (mutation.type.startsWith("vehicleInspection")) {
    store.dispatch("vehicleInspection/handleStoreMutation");
  }
});

export default store;
