<template>
  <div v-click-outside="removeEmpty" class="multi-text-input">
    <div v-for="(input, index) in items" :key="`itemInput-${index}`" class="text-input-item">
      <label class="input">
        <input v-model="input.value" type="text" class="w-lg" :placeholder="placeholder" :maxlength="maxLength"
          :class="{ 'maxlen-warn': isNearMaxLength[index] }" />
        <!-- spacer when no remove-icon -->
        <i v-show="items[0].value == ''"></i>
        <!-- remove icon -->
        <i v-show="items.length > 1 || items[0].value !== ''" class="cursor-pointer remove-icon"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" @click="removeField(index)">
        </i>
      </label>

      <!--  add icon -->
      <i v-if="isLast(index)" class="add-icon" :class="{
    inactive: !input.value,
    'cursor-pointer': !!input.value,
    'cursor-not-allowed': !input.value,
  }" @click="addField(input)"></i>
    </div>
  </div>
</template>

<script>
import { utilities } from "../utils.js";
import vClickOutside from "../directives/v-click-outside.js";

export default {
  name: "MultiTextInput",
  components: {},
  directives: {
    clickOutside: vClickOutside,
  },
  props: {
    inputId: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: String, // json
      default: null,
    },
    maxLength: {
      type: Number,
      default: 64,
    }
  },
  emits: ["change"],

  data: () => ({
    items: [{ value: "" }],
  }),

  computed: {
    isNearMaxLength() {
      return this.items.map(item => item.value.length >= this.maxLength - 5);
    }

  },

  watch: {
    items: {
      handler(newValue) {
        if (this.items) {
          const val = JSON.stringify(
            newValue
              .filter((v) => v.value !== "")
              .map((v) => {
                if (v.value !== "") {
                  return v.value;
                }
                return false;
              })
          );
          this.$emit("change", val);
          utilities.emitValueChanged(this.$el, this.inputId, val);
        }
      },
      deep: true,
    },
  },

  mounted() {
    let valueAsrray = [];
    if (this.value) {
      valueAsrray = JSON.parse(this.value) || [];
    }
    if (valueAsrray.length == 0) {
      valueAsrray = [""]; // initialize empty first item
    }

    if (valueAsrray && Array.isArray(valueAsrray)) {
      this.items = [];
      for (let item of valueAsrray) {
        this.items.push({ value: item });
      }
    } else {
      this.items = [{ value: "" }];
    }
  },

  methods: {
    isLast(index) {
      return this.items.length === index + 1;
    },
    addField(input) {
      if (input.value) {
        this.items.push({ value: "" });
      }
    },
    removeField(index) {
      if (this.items.length == 1 && index === 0) {
        this.items[0].value = "";
      } else {
        this.items.splice(index, 1);
      }
    },
    removeEmpty() {
      // only last field can be empty.. at least that's the idea
      if (this.items.length > 1) {
        if (this.items[this.items.length - 1].value === "") {
          this.removeField(this.items.length - 1);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../scss/components/_multitextinput.scss";
</style>
