import vClickOutside from "./v-click-outside.js";
const HAS_WINDOWS = typeof window !== "undefined";

// eslint-disable-next-line no-unused-vars
const mounted = (el, binding, _vnode, prevVnode) => {
  const { value } = binding;

  const svgHtml = `  
  <svg
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"    
  >
    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
    <path
      d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"
    />
  </svg>
  `;

  const tooltipButton = document.createElement("i");
  tooltipButton.innerHTML = svgHtml;

  const tooltipInfo = document.createElement("div");
  tooltipInfo.classList.add("tooltip-info", "hidden");

  tooltipInfo.setAttribute("v-click-outside", "hidden");

  // funcky hack because we can't pass a callback function, so solved using a custom event
  tooltipInfo.addEventListener("clickOutside", () => {
    const tooltip = el.querySelector(".tooltip");
    if (tooltip) {
      tooltip.classList.remove("open");
    }
  });

  const helpText = value;

  tooltipInfo.innerHTML = helpText;

  const tooltip = document.createElement("span");
  tooltip.classList.add("tooltip");

  const tooltipClasses = el.getAttribute("data-help-info-button-classes");
  if (tooltipClasses) {
    tooltip.classList.add(...tooltipClasses.split(" "));
  }

  tooltip.appendChild(tooltipButton);
  tooltip.appendChild(tooltipInfo);
  el.appendChild(tooltip);

  tooltipButton.addEventListener("click", (event) => {
    event.preventDefault();
    event.stopPropagation();

    // hack: close all open tooltips
    const shadowRoot = tooltipButton.getRootNode({ composed: false });
    const elements = shadowRoot.querySelectorAll(".tooltip-info");
    const contElements = shadowRoot.querySelectorAll(".tooltip");
    elements.forEach((element) => {
      element.classList.add("hidden");
    });
    contElements.forEach((element) => {
      element.classList.remove("open");
    });

    tooltipInfo.classList.toggle("hidden");
    tooltip.classList.toggle("open");

    if (!tooltipInfo.classList.contains("hidden")) {
      // tooltip will be shown.. now calculate the position on the page
      // bereken of uitgeklapte tooltip te laag op pagina komt, zo niet dan omhoog flippen

      tooltipInfo.classList.remove("align-top");
      tooltipInfo.classList.remove("align-left");

      const root = shadowRoot.querySelector('.tradein-root');
      const viewportBottom = root.getBoundingClientRect().bottom;
      const viewportRight = root.getBoundingClientRect().right;
      
      const divBottom = tooltipInfo.getBoundingClientRect().bottom;
      const divRight = tooltipInfo.getBoundingClientRect().right;
      const divLeft = tooltipInfo.getBoundingClientRect().left;
      const divWidth = tooltipInfo.getBoundingClientRect().width;
      const distanceToBottom = viewportBottom - divBottom;
      const distanceToRight = viewportRight - divRight;
      const thresholdBottom = 80;
      const thresholdRight = 1;
      const thresholdLeft = 1;

      tooltipInfo.classList.toggle(
        "align-top",
        distanceToBottom <= thresholdBottom,
      );
      tooltipInfo.classList.toggle(
        "align-left",
        divLeft - divWidth >= thresholdLeft &&
          distanceToRight <= thresholdRight,
      );
    }
  });

  applyDirectives(tooltip);
};

const applyDirectives = (node) => {
  const directives = [{ name: "v-click-outside", code: vClickOutside }];
  directives.forEach((directive) => {
    const elements = node.querySelectorAll(`[${directive.name}]`);
    elements.forEach((el) => {
      const value = el.getAttribute(`${directive.name}`);
      if (typeof directive.code.beforeMount === "function") {
        directive.code.beforeMount(el, { value: value });
      }
      if (typeof directive.code.mounted === "function") {
        directive.code.mounted(el, { value: value });
      }
    });
  });
};

const unmounted = (el) => {
  const tooltipButton = el.querySelector("i");
  const tooltip = el.querySelector(".tooltip");

  tooltipButton.removeEventListener("click", () => {});
  el.removeChild(tooltip);
};

const directive = {
  mounted,
  unmounted,
};

export default HAS_WINDOWS ? directive : {};
