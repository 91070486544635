<template>
  <input
    v-model="value"
    size="8"
    class="licenseplate-nl"
    @keydown.enter.prevent="submit"
  />
</template>

<script>
import { utilities } from "../utils.js";
export default {
  name: "LicensePlate",
  components: {},
  props: {
    registrationNumber: {
      type: String,
      default: null,
    },
    inputId: {
      type: String,
      required: true,
    },
    submitOnEnter: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["change", "submitMyForm"],

  data: () => ({
    value: null,
    emittedValue: null,
  }),

  computed: {
    isValid() {
      return this.value && this.value.length === 8; // including 2 dashes
    },
  },

  watch: {
    value() {
      if (this.value) {
        this.emitNormalizedValue();
      }
    },
  },

  mounted() {
    this.value = this.registrationNumber;
  },

  methods: {
    emitNormalizedValue() {
      const formatted = this.formatKenteken(this.value);
      if (this.emittedValue !== formatted) {
        this.value = formatted;
        this.emittedValue = formatted;
        this.$emit("change", this.value);
        const normalizedValue = this.value
          .trim()
          .toUpperCase()
          .replace(/-/g, "");
        utilities.emitValueChanged(this.$el, this.inputId, normalizedValue);
      }
    },
    submit() {
      if (this.submitOnEnter && this.isValid) {
        this.$emit("submitMyForm");
        const event = new CustomEvent("submitMyForm", {
          bubbles: true,
          composed: true,
        });
        this.$el.dispatchEvent(event);
      }
    },

    /*  basic formatter found on:
     *  https://blog.kenteken.tv/2011/05/06/code-snippets-formatteren-rdw-kenteken/
     */
    formatKenteken(kenteken) {
      kenteken = kenteken.trim().toUpperCase().replace(/-/g, "");
      let pair = 0;
      if (kenteken.length == 6) {
        for (var i = 0; i < kenteken.length - 1; i++) {
          if (isNaN(kenteken[i]) != isNaN(kenteken[i + 1])) {
            kenteken = kenteken.substr(0, i + 1) + "-" + kenteken.substr(i + 1);
            pair = 0;
            i++;
          } else {
            pair++;
            if (pair > 2) {
              kenteken = kenteken.substr(0, i) + "-" + kenteken.substr(i);
              pair = 0;
            }
          }
        }
      }
      return kenteken;
    },
  },
};
</script>

<style lang="scss">
@import "../scss/licenseplate-nl.scss";
.licenseplate-nl {
  font-size: 2em;
}
</style>
