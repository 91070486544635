import axios from "axios";

let workflowInterceptor = null; // Reference to the workflow interceptor
let userReferenceInterceptor = null; // Reference to the userReference interceptor
let userTokenInterceptor = null; // Reference to the userToken interceptop

function setupWorkflowInterceptor(workflowId) {
  // Remove the existing workflow interceptor if it exists
  if (workflowInterceptor !== null) {
    axios.interceptors.request.eject(workflowInterceptor);
  }

  // Add a new request interceptor to set token for Django backend
  workflowInterceptor = axios.interceptors.request.use((config) => {
    const domain_re = /trade_in/; // Regular expression to match the domain or URL pattern for which the headers should be modified

    if (domain_re.exec(config.url)) {
      config.headers["withCredentials"] = true;
      config.headers["workflowId"] = workflowId;
    }
    return config;
  });
}

function setupUserReferenceInterceptor(userReference) {
  // Remove the existing userReference interceptor if it exists
  if (userReferenceInterceptor) {
    axios.interceptors.request.eject(userReferenceInterceptor);
  }

  // Add a new request interceptor to set userReference for Django backend
  userReferenceInterceptor = axios.interceptors.request.use((config) => {
    const domain_re = /trade_in/; // Regular expression to match the domain or URL pattern for which the headers should be modified

    if (domain_re.exec(config.url)) {
      config.headers["withCredentials"] = true;
      config.headers["userReference"] = userReference;
    }
    return config;
  });
}

function setupUserTokenInterceptor(userToken) {
  // Remove the existing userToken interceptor if it exists
  if (userTokenInterceptor) {
    axios.interceptors.request.eject(userTokenInterceptor);
  }

  // Add a new request interceptor to set userReference for Django backend
  userTokenInterceptor = axios.interceptors.request.use((config) => {
    const domain_re = /trade_in/; // Regular expression to match the domain or URL pattern for which the headers should be modified

    if (domain_re.exec(config.url)) {
      config.headers["withCredentials"] = true;
      config.headers["userToken"] = userToken;
    }
    return config;
  });
}

const state = {
  workflowId: "",
  userReference: "",
  userToken: "",
};

const getters = {};

const actions = {
  setWorkflowId(context, workflowId) {
    context.commit("setWorkflowId", workflowId);
    setupWorkflowInterceptor(workflowId);
  },

  setUserId(context, userReference) {
    context.commit("setUserId", userReference);
    setupUserReferenceInterceptor(userReference);
  },

  setUserToken(context, userToken) {
    context.commit("setUserToken", userToken);
    setupUserTokenInterceptor(userToken);
  },
};

const mutations = {
  setWorkflowId(state, workflowId) {
    state.workflowId = workflowId;
  },
  setUserId(state, userReference) {
    state.userReference = userReference;
  },
  setUserToken(state, userToken) {
    state.userToken = userToken;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
