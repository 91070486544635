<template>
  <div>
    <div class="header" @mousedown="startTimer" @mouseup="clearTimer">

      <div class="dropdowns" v-if="showDropdowns">
        <div class="row">
          <div class="column">
            <label for="workflowId">Workflow ID:</label>
            <select id="workflowId" v-model="selectedWorkflowSlug" @change="toggleTradeIn">
              <option v-for="opt of workflowOptions" :id="opt.id" :value="opt.slug">{{ opt.name }}</option>
            </select>
          </div>

          <div class="column">
            <label for="userReference">User Reference:</label>
            <input type="text" id="userReference" :value="selectedUserReference" @blur="updateUserReference">
          </div>

          <div class="column">
            <label for="customerClass">Customer Class:</label>
            <select id="customerClass" v-model="selectedCustomerClass">
              <option value="bca">bca</option>
              <option value="justlease">justlease</option>
              <option value="">[empty]</option>
            </select>
          </div>

          <div class="column">
            <label for="userToken">User Token:</label>
            <input type="text" id="userToken" :value="selectedUserToken" @blur="updateUserToken">
          </div>
        </div>
        <p>
          Share URL: <a :href="this.shareURL">{{ this.shareURL }}</a>
        </p>
      </div>

      <div v-if="!showDropdowns">
        Trade-In module ({{ environment }}) voor {{ selectedWorkflowName }} (style: {{ selectedCustomerClass || '[empty]'
        }}).

      </div>
    </div>

    <template v-if="selectedWorkflowSlug">
      <template v-if="noWebcomponent">
        <TradeIn :key="tradeInKey" :workflow-id="selectedWorkflowSlug" :user-reference="selectedUserReference"
          :customer-class="selectedCustomerClass" :user-token="selectedUserToken" />
      </template>
      <template v-else>
        <trade-in :key="tradeInKey" :workflow-id="selectedWorkflowSlug" :user-reference="selectedUserReference"
          :customer-class="selectedCustomerClass" :user-token="selectedUserToken">
        </trade-in>
      </template>
    </template>

  </div>
</template>

<script>
import axios from "axios";
import TradeIn from "./TradeIn.ce.vue";

export default {
  name: "MainDevelop",
  components: { TradeIn },
  data() {
    return {
      workflowOptions: [],
      selectedWorkflowSlug: "",
      selectedUserReference: "",
      selectedCustomerClass: "",
      selectedUserToken: "",
      noWebcomponent: process.env.APP_NO_WEBCOMPONENTS,
      showDropdowns: false,
      environment: process.env.NODE_ENV,
      timer: null,
    };
  },
  methods: {
    updateUserToken(event) {
      this.selectedUserToken = event.target.value;
      localStorage.selectedUserToken = this.selectedUserToken;
    },

    updateUserReference(event) {
      this.selectedUserReference = event.target.value;
      localStorage.selectedUserReference = this.selectedUserReference;
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.showDropdowns = !this.showDropdowns;
      }, 5000);
    },
    clearTimer() {
      clearTimeout(this.timer);
    }

  },
  watch: {
    selectedWorkflowSlug(value) {
      localStorage.selectedWorkflowSlug = value;
    },

    selectedCustomerClass(value) {
      localStorage.selectedCustomerClass = value;
    },
  },
  computed: {

    shareURL() {
      const currentURL = new URL(window.location.href);
      currentURL.search = '';  // remove existing GET parameters
      currentURL.searchParams.set('workflow', this.selectedWorkflowSlug);
      currentURL.searchParams.set('style', this.selectedCustomerClass);
      return currentURL.href;
    },

    selectedWorkflow() {
      return this.workflowOptions.find(obj => obj.slug === this.selectedWorkflowSlug);
    },
    selectedWorkflowName() {
      return this.selectedWorkflow?.name;
    },
    tradeInKey() {
      // key to dertermine if tradeIn component should be recreated
      return `${this.selectedWorkflowSlug}|${this.selectedUserToken}|${this.selectedUserReference}`;
    },
  },
  created() {
    const url = `${import.meta.env.VITE_APP_CLIENT_GATEWAY_URL}${import.meta.env.VITE_APP_TRADEIN_BASE_URI}workflows/workflow-slugs`;

    axios.get(url)
      .then((response) => {
        this.workflowOptions = response.data;
      })
      .catch((error) => { console.log(error); });

    if (localStorage.selectedWorkflowSlug) {
      this.selectedWorkflowSlug = localStorage.selectedWorkflowSlug;
    }

    if (localStorage.selectedUserReference) {
      this.selectedUserReference = localStorage.selectedUserReference;
    }

    if (localStorage.selectedCustomerClass) {
      this.selectedCustomerClass = localStorage.selectedCustomerClass;
    }

    if (localStorage.selectedUserToken) {
      this.selectedUserToken = localStorage.selectedUserToken;
    }

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('showDropdowns')) {
      this.showDropdowns = true;
    }

    if (queryParams.has('workflow')) {
      this.selectedWorkflowSlug = queryParams.get('workflow');
    }

    if (queryParams.has('style')) {
      this.selectedCustomerClass = queryParams.get('style');
    }

    if (!this.selectedUserReference && this.selectedWorkflowSlug) {
      this.selectedUserReference = `${this.selectedWorkflowSlug}-demo-user`;
    }
  },
}
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 10px;
  gap: 20px;
}

.header {
  background-color: #d3e1f7;
  /* Less subtle blue background */
  font-weight: bold;
  padding: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}
</style>
