import {
  andDependencies,
  create,
  largerDependencies,
  orDependencies,
  parserDependencies,
  smallerDependencies,
} from "mathjs";

const config = {};

// Create just the functions we need instead of all, so the final build if much smaller
// see: https://mathjs.org/docs/custom_bundling.html
// alternative for all functions: const math = create(all, config);
const math = create(
  {
    parserDependencies,
    andDependencies,
    orDependencies,
    smallerDependencies,
    largerDependencies,
  },
  config,
);

/* Math.js doesn't handle string comparison by default, so add overrides */
math.import(
  {
    equal: function (a, b) {
      return a === b;
    },
    unequal: function (a, b) {
      return a !== b;
    },

    toDate(val) {
      return new Date(val);
    },

    toDateFromParts(baseFieldName, data) {
      const year = data[baseFieldName + "_year"];
      const month = data[baseFieldName + "_month"];
      const day = data[baseFieldName + "_day"];
      if (year && month && day) {
        return new Date(year, month - 1, day);
      }
    },

    diffDays: function (date1, date2) {
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },

    isInteger: function (value) {
      const parsed = parseInt(value);
      return !isNaN(parsed);
    },

    mileage_calc: function (
      amountField,
      startDateField,
      endDateBaseField,
      data,
    ) {
      const amount = data[amountField];
      const startDate = math.toDate(data[startDateField]);
      const curDate = new Date().setHours(0, 0, 0, 0);

      if (!data[endDateBaseField]) {
        return amount;
      }

      const endDate = math.toDate(data[endDateBaseField], data);
      const rate = amount / (curDate - startDate);
      const regression = rate * (endDate - startDate);
      return Math.round(regression);
    },
  },
  { override: true },
);

export const utilities = {
  fix_url_double_slashes: (url) => {
    return url.replace(/([^:]\/)\/+/g, "$1");
  },

  addCacheBusterToUrl: (url, cacheBusterParamName = "cache_buster") => {
    var cacheBuster = Date.now(); // Or use Math.random()
    var urlObject = new URL(url);
    urlObject.searchParams.set(cacheBusterParamName, cacheBuster);
    var urlWithCacheBusting = urlObject.toString();
    return urlWithCacheBusting;
  },

  /*
   * Emit an event which bubbles up, even accross shadowroots
   * This is a generic event ehich the root component catches and
   * will set the value to the hidden input with this inputId
   */
  emitValueChanged: (element, inputId, value) => {
    const event = new CustomEvent("someValueChanged", {
      bubbles: true,
      composed: true,
      detail: {
        getValue: () => {
          return value;
        },
        inputId: () => {
          return inputId;
        },
      },
    });
    element.dispatchEvent(event);
  },

  compareFormData: (formData1, formData2) => {
    if (formData1 === formData2) {
      return true;
    }

    if (formData1.size !== formData2.size) {
      return false;
    }

    const formData1Keys = [...formData1.keys()];
    const formData2Keys = [...formData2.keys()];

    if (!formData1Keys.every((key) => formData2Keys.includes(key))) {
      return false;
    }

    for (const key of formData1Keys) {
      const formData1Values = formData1.getAll(key);
      const formData2Values = formData2.getAll(key);

      if (formData1Values.length !== formData2Values.length) {
        return false;
      }

      for (const value of formData1Values) {
        if (!formData2Values.includes(value)) {
          return false;
        }
      }
    }

    return true;
  },

  math,

  eval: (expression, form) => {
    const formData = new FormData(form);
    const parser = utilities.math.parser();

    let data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    // hack for disabled inputs
    form.querySelectorAll(":disabled").forEach((el) => {
      data[el.name] = el.value;
    });

    parser.set("data", data);

    const outcome = parser.evaluate(expression);
    return outcome;
  },

  evaluateConditionals: (form) => {
    const conditionalElements = form.querySelectorAll(
      "[data-show-conditional]",
    );

    for (const el of conditionalElements) {
      const expr = el.getAttribute("data-show-conditional");
      const outcome = utilities.eval(expr, form);
      el.classList.toggle("hidden", !outcome);

      if (!outcome) {
        const requiredElements = el.querySelectorAll("[required]");
        for (const requiredEl of requiredElements) {
          requiredEl.setAttribute("data-required", true);
          requiredEl.required = false;
        }
      } else {
        const requiredElements = el.querySelectorAll("[data-required='true']");
        for (const requiredEl of requiredElements) {
          requiredEl.required = true;
        }
      }
    }
  },

  evaluateCopyData: (form, event) => {
    const copyElements = form.querySelectorAll("[data-copy-value-onchange]");
    for (const el of copyElements) {
      const source = el.getAttribute("data-copy-value-onchange");
      if (event.target.name === source) {
        el.value = form[source].value;
      }
    }
  },

  safeSetValue(element, value) {
    if (element.nodeName === "TRADEIN-NUMBER-FORMAT") {
      // er is iets geks aan de hand met dit widget.. anders krijg je een dubbele input
      setTimeout(() => {
        element.value = value;
      }, 0);
    } else {
      element.value = value;
    }
  },

  evaluateExpression: (form, event) => {
    const expressionElements = form.querySelectorAll(
      "[data-evaluate-expression]",
    );
    for (const el of expressionElements) {
      const expression = el.getAttribute("data-evaluate-expression");
      const sourceFieldStr = el.getAttribute("data-on-field-change");

      if (sourceFieldStr) {
        let parsedSourceFields = sourceFieldStr;
        try {
          parsedSourceFields = JSON.parse(sourceFieldStr);
          if (!Array.isArray(parsedSourceFields)) {
            parsedSourceFields = [parsedSourceFields];
          }
        } catch (e) {
          // whatever
        }

        if (event) {
          for (const sourceField of parsedSourceFields) {
            let event_target_name = event.target.name;
            // could be a CustomEvent
            if (!event_target_name) {
              if (event.detail && event.detail.inputName) {
                event_target_name = event.detail.inputName();
              }
            }

            if (event_target_name === sourceField) {
              const outcome = utilities.eval(expression, form);
              utilities.safeSetValue(el, "" + outcome);
            }
          }
        }
      } else {
        const outcome = utilities.eval(expression, form);
        utilities.safeSetValue(el, "" + outcome);
      }
    }
  },
};
