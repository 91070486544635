export class UploadedImage {
  constructor(name, data) {
    this.name = name;
    this.data = data;
    this.url = null;
    this.uploadedProgress = 0;
    this.isUploading = false;
  }

  /* the payload for uploading to the backend */
  get uploadPayload() {
    return {
      name: this.name,
      data: this.data,
    };
  }

  get payloadForInput() {
    return {
      name: this.name,
      url: this.url,
    };
  }

  get removePayload() {
    return {
      id: this.name,
    };
  }

  get key() {
    // we could simply return the filename. But how well does this work
    // if the same name appears in seperate folders? The path is not in the selected
    // file, so I don't know how to get a proper unique key other than calculate a hash
    // on the base64 string..
    if (this.useHashForKey) {
      // took from https://stackoverflow.com/a/52171480
      const s = this.data || this.name;
      for (var i = 0, h = 9; i < s.length;) {
        h = Math.imul(h ^ s.charCodeAt(i++), 9 ** 9);
      }
      const hash = h ^ (h >>> 9);
      return hash;
    } else {
      return this.name;
    }
  }
}
