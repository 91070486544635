import axios from "axios";

const HAS_WINDOWS = typeof window !== "undefined";

const loadImage = async (img) => {
  const url = img.dataset.src;
  const response = await axios.get(url, { responseType: "arraybuffer" });
  if (response.status == 200) {
    const MAX_CHUNK_SIZE = 65535; // Maximum number of Unicode values per chunk
    const uint8Array = new Uint8Array(response.data);
    const chunks = [];
    for (let i = 0; i < uint8Array.length; i += MAX_CHUNK_SIZE) {
      const chunk = uint8Array.subarray(i, i + MAX_CHUNK_SIZE);
      chunks.push(String.fromCharCode(...chunk));
    }
    const b64 = btoa(chunks.join(""));
    const imgData =
      "data:" + response.headers["content-type"] + ";base64," + b64;
    img.src = imgData;
  }
  return response;
};

// called right before the element is inserted into the DOM.
const beforeMount = (el, binding, vnode, prevVnode) => {
  el.classList.add("image-loading");
  if (el.dataset.src) {
    loadImage(el).then(() => {
      el.classList.remove("image-loading");
    });
  }
};

const directive = {
  beforeMount,
};

export default HAS_WINDOWS ? directive : {};
