const state = {
  version: 1.0,
  storeLoaded: false,
  lastUrl: null,
};

const getters = {
  storeLoaded(state) {
    return state.storeLoaded;
  },
  lastUrl(state) {
    return state.lastUrl;
  },
};

const mutations = {
  setStoreLoaded(state, payload) {
    state.storeLoaded = payload;
  },
  resetState(state, payload) {
    for (const [key, value] of Object.entries(payload)) {
      state[key] = value;
    }
  },
  setLastUrl(state, url) {
    state.lastUrl = url;
  },
};

const actions = {
  handleStoreMutation({ state }) {
    const itemsToStore = {
      version: state.version,
      lastUrl: state.lastUrl,
    };
    sessionStorage.setItem("store", JSON.stringify(itemsToStore));
  },

  initialiseStore(context) {
    return new Promise((resolve) => {
      if (context.getters.storeLoaded) {
        resolve();
      }

      // Check if the store exists
      if (sessionStorage.getItem("store")) {
        const storedObj = JSON.parse(sessionStorage.getItem("store"));
        // Check the version stored against current. If different, don't load the cached version
        if (storedObj.version === context.state.version) {
          context.commit("resetState", storedObj);
        }
      }
      context.commit("setStoreLoaded", true);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
