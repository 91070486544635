import axios from 'axios';

class GlobalData {
  constructor() {
    this._cancelToken = null;
    this.resetCancelToken();
  }

  get cancelToken() {
    return this._cancelToken;
  }

  resetCancelToken() {    
    this._cancelToken = axios.CancelToken.source();
  }

  static getInstance() {
    if (!GlobalData.instance) {
      GlobalData.instance = new GlobalData();
    }
    return GlobalData.instance;
  }
}

const globalData = GlobalData.getInstance();
export default globalData;
