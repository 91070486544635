import axios from "axios";

let csrfInterceptor = null; // Reference to the CSRF interceptor

function setupCsrfInterceptor(csrfToken) {
  // Remove the existing CSRF interceptor if it exists
  if (csrfInterceptor) {
    axios.interceptors.request.eject(csrfInterceptor);
  }

  // Add a new request interceptor to set CSRF token for Django backend
  csrfInterceptor = axios.interceptors.request.use((config) => {
    const domain_re = /trade_in/; // Regular expression to match the domain or URL pattern for which the headers should be modified

    if (domain_re.exec(config.url)) {
      config.headers["X-CSRFToken"] = csrfToken;
    }
    return config;
  });
}

const state = {
  csrfToken: null,
  prevStep: null,
};

const getters = {
  csrfToken(state) {
    return state.csrfToken;
  },
  prevStep(state) {
    return state.prevStep;
  }
};

const actions = {
  setCsrfToken(context, csrfToken) {
    context.commit("setCsrfToken", csrfToken);
    setupCsrfInterceptor(csrfToken);
  },
};

const mutations = {
  setCsrfToken(state, csrfToken) {
    state.csrfToken = csrfToken;
  },
  setPrevStep(state, prevStep) {
    state.prevStep = prevStep;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
